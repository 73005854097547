<template>
	<div>
		<div v-if="viewno == 1">
			<div class="col-md-12 font">
				<div class="cardbox">
					<div class="col-md-12">
						<div class="row" style="margin-top: 20px">
							<div class="col-md-6">
								<h3 class="page-header">
									<i class="fa fa-linode animated bounceInDown show-info"></i>
									Model List
								</h3>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-10">
										<div class="row" style="padding-top: 18px; justify-content: flex-end">
											<div class="col-md-2" style="margin-right: 25px">
												<button @click="reloadFun()" class="btn btn-primary">
													<i class="fa fa-refresh"></i> Refresh
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="memberdivtable_1">
						<div class="card-body" style="border: 1px solid #e9dbdb">
							<div class="form-group mb-1">
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-4">
											<p><b>Product Name</b></p>
											<!-- <autocomplete ref="autocomplete" :source="distributionGroupsEndpoint"
												input-class="form-control form-cascade-control input-box-short"
												results-property="data" :results-display="formattedDisplay"
												v-model="searchcategory" @selected="addDistributionGroup">
											</autocomplete> -->

											<!-- :request-headers="authHeaders" -->
											<select v-model="categoryid"
												class="form-control form-cascade-control input-small">
												<option v-for="filter in filters" :key="filter.id"
													:value="filter.id">{{ filter.name }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<p><b>Company Name</b></p>
											<select class="form-control form-cascade-control input-small .dropdown-menu"
												v-model="brandid">
												<option v-for="item in companies" :key="item.id" :value="item.id">{{
													item.value }}
												</option>
											</select>
											<!-- <input type="Search" v-model="searchname" class=" form-control form-cascade-control input-box-short " /> -->
										</div>
										<!-- <div class="col-md-3">
											<p><b>Product Name</b></p>
											<input type="Search" v-model="searchname"
												class="form-control form-cascade-control input-box-short " />
										</div> -->
										<div class="col-md-4">
											<p><b>Model Name</b></p>
											<input type="Search" v-model="searchname"
												class="form-control form-cascade-control input-box-short " />
										</div>
										
									</div>
								</div>
								<!-- <div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<p><b>Category</b></p>
											<select class="form-control form-cascade-control input-small"
												v-model="categoryid" ref="myInput">
												<option selected>----Select-----</option>
												<option v-for="item in categories" v-bind:value="item.id"
													:key="item.id">{{ item.name }}</option>
											</select>
											
											<br />
										</div>
										<div class="col-md-3">
											<p><b>Product Name</b></p>
											<input type="Search" v-model="searchname" class="
                          form-control form-cascade-control
                          input-box-short
                        " />
										</div>
										<div class="col-md-3">
											<p><b>Model Name</b></p>
											<input type="Search" v-model="searchname" class="
                          form-control form-cascade-control
                          input-box-short
                        " />
										</div>
										<div class="col-md-3">
											<p><b>Company Name</b></p>
											<input type="Search" v-model="searchname" class="
												form-control form-cascade-control
												input-box-short
												" />
										</div>
									</div>
								</div> -->
								<div class="col-md-12">
									<div class="col-md-1 d-flex">
										<button type="button" @click="search()"
											class="btn bg-success text-white username btnsearch">
											Search
										</button>
										<button type="button" @click="cancel()"
											class="btn bg-danger text-white username btnsearch ml-10">
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12 mt-20">
				<div class="row">
					<div class="col-md-1">
						<strong>Show Rec.</strong>
					</div>
					<div class="col-md-2">

						<select class="form-control col-mb-1 form-cascade-control" v-model="noofrec" id="rec"
							@change="refresh()">
							<option value=10>10</option>
							<option value=25>25</option>
							<option value=50>50</option>
							<option value=100>100</option>
						</select>
					</div>
					<div class="col-md-5">
						<textarea class="form-control delradius form-cascade-control" cols="60" rows="1"
							v-model="productdescription" placeholder="Add Description"></textarea>
						<button class="btn btn-success" @click="showdescription()" v-if="false"></button>
					</div>
					<div class="col-md-3">
						<select class="form-control col-mb-1 form-cascade-control" v-model="printchoice">
							<option value="1">Advisors Print</option>
							<option value="2">Customers Print</option>
						</select>
					</div>
					<div class="col-md-1 text-right">
						<button class="btn btn-xs pdf" v-if="clickpdf == false" @click="generatepdf()"><span><i
									class='bx bxs-file-pdf' style="font-size:40px;"></i></span></button>
						<span v-if="clickpdf" class="spinner"></span>
					</div>
				</div>
			</div>
			<div class="cardbox1">
				<div class="col-md-12" style="padding: 0px">
					<div class="table-responsive">
						<table class="table table-font table-bordered table-hover display">
							<tr class="card_1">
								<th>S.No</th>
								<th>Category</th>
								<th>Company Name</th>
								<th style="width: 20%">Product Name</th>
								<th>Model Name.</th>
								<th v-if="false">Model No.</th>
								<th>M.R.P</th>
								<th>Cash Sale Price</th>
								<th>Emi Price</th>
								<th>D.P</th>
								<th>Emi Desc.</th>
								<th>Short Description</th>
								<th v-if="false">Attributes to be selected by the user</th>
								<th v-if="printchoice == '1'">INCENTIVE</th>
								<th>Image</th>
							</tr>
							<product-row v-for="(item, index) in allproducts" :key="item.id" :product="item" :indx="index"
								:currentpage="currentpage" :noofrec="noofrec"></product-row>
						</table>
					</div>
				</div>
			</div>
			<div style="width:20%;height:30%;position:absolute;" v-if="titleshow == true && false"></div>
			<div class="col-md-12" style="margin-top: 20px">
				<nav aria-label="Page navigation example" style="text-align: right !important">
					<paginate style="margin: 0px !important; float: right" :page-count="noofpages"
						:click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
						:container-class="'pagination'">
					</paginate>
				</nav>
			</div>
		</div>
		<div v-if="viewno == 2">
			<product-form></product-form>
		</div>

		<image-modal></image-modal>
	</div>
</template>
<script>
// import Autocomplete from "vuejs-auto-complete";
import ProductRow from "./ProductRow";
import ProductForm from "./ProductForm";
import ImageModal from "../../components/utilities/ImageModal.vue";
import Constants from '../../components/utilities/Constants'
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex'
export default {
	mixins: [Constants],
	components: {
		ProductForm, ProductRow, ImageModal, Paginate
	},
	data() {
		return {
			categoryArray: [],
			status: { '1': 'Disabled', '0': 'Active' },
			isedit: false,
			product: null,
			size: 50,
			removeitem: null,
			productcategorymap: [],
			productattribmap: [],
			predefined: [],
			searchname: '',
			searchcategory: '',
			searchrole: 0,
			searchstatus: 0,
			stockstatus: 0,
			categoryids: [],
			currentpage: 1,
			noofrec: 10,
			printchoice: 1,
			productdescription: '',
			titleshow: false,
			//productattribmap:[],
			clickpdf: false,
			categoryid: 0,
			brandid:0,
			companies:[]
		}
	},
	mounted() {
		//this.$store.dispatch("fetchfeature")
		this.refresh();
	},
	computed: {
		filters() {
			return this.categories.filter(item => item.parent_id > 0)
		},
		...mapGetters([
			'viewno', 'edititem', 'perpagerecord', 'allproducts', 'loggedinuser',
			'allproductscount', 'categories','attributes'
		]),
		noofpages() {
			return Math.ceil(parseInt(this.allproductscount) / parseInt(this.noofrec))
		},
		
	},
	methods: {
		clickCallback(currentpage) {
			this.currentpage = currentpage
			this.$store.commit("assigncurrentpage", currentpage);
			// this.$store.commit("assigncurrentpage",currentpage);
			this.refresh();
		},
		distributionGroupsEndpoint(input) {
			return window.axios.defaults.baseURL + '/autocomplete/category/search?query=' + input;
		},
		addDistributionGroup(group) {
			this.searchcategory = group.value;
		},

		formattedDisplay(result) {
			return result.name;
		},
		processProductallListResponse(data) {
			console.log(data)
			this.$store.commit('assignloadingstatus', 0)
		},
		searchName() {
			let param = { searchname: this.searchname, searchstatus: this.searchstatus, searchcategory: this.searchcategory, searchrole: this.searchrole, image: this.images }
			this.$http.post('api/product/search', param)
				.then((response) => this.processProductResponse(response.data))
				.catch((err) => {
					console.log(err)
				});
		},


		processAttributes(data) {
			this.$store.commit('assignattributes', data);

			//this.createarrayofattributes();
		},
		getimageurl(imageitem) {
			//return window.axios.defaults.baseURL+"/products/"+imageitem.id+'/'+this.size+'x'+this.size+'/'+item.url+"-"+imageitem.id+".jpg";
			return window.axios.defaults.baseURL + "/uploads/" + imageitem.name;
		},
		processProductResponse(data) {
			this.$store.commit("assignloadingstatus", 0);
			this.$store.commit('assignallproducts', data.products);
			this.$store.commit('assignallproductscount', data.count);
			// this.$store.dispatch("fetchproductscount");
		},
		processCategoryResponse(data) {
			this.categories = data;
			this.$store.commit('assigncategories', data);
			this.categoryArray = data.reduce((acc, item) => {
				acc[item.id] = item.name;
				return acc;
			}, {});
			this.categoryArray[0] = 'All/None';
		},
		triggeredit(item) {
			this.isedit = !this.isedit;
			this.product = item;

		},
		cancel() {
			this.$store.commit('assignloadingstatus', 0)
			this.categoryid = 0
			this.brandid=0,
			this.searchname=''
		},
		editingcompleted() {
			//if found then update otherwise add
			let index = this.products.findIndex(block => block.id == this.product.id)
			if (index > -1) {
				this.$set(this.products, index, this.product);
			} else {
				this.products.push(this.edititem);
			}
			this.processProductResponse(this.products);
		},
		triggerimagemodal(item) {
			this.product = item;
			this.$modal.show('imagemodal');
		},
		saveimage(imageid) {
			//post the category id and the image id
			let param = { productid: this.edititem.id, imageid: imageid };
			if (this.edititem != null) {
				this.$http.post('api/product/saveimage', param)
					.then((response) => {
						this.refreshimages(response.data)
					}).catch((err) => {
						console.log(err)
					});
			}
		},
		refreshimages(data) {
			let product = this.allproducts.find(block => block.id == this.edititem.id)
			product.images = data;

			this.$store.commit("createupdateallproducts", product);
		},

		checkboxevent(item, e) {
			console.log(e.target.checked);
			item.onsale = e.target.checked ? 1 : 0;
			this.$http.post('api/product', item);
		},
		refresh() {
			this.$store.commit('assignloadingstatus', 0)
			let param = { noofrec: this.noofrec ?? 50, currentpage: this.currentpage ?? 1, categoryid: this.categoryid }
			this.$store.dispatch("fetchproducts", param)
			this.$store.dispatch('fetchcategories')
			this.$store.dispatch('fetchattributes')
			let param2 ={categoryid: this.categoryid}
			this.$store.dispatch('fetchproductscount',param2)
			setTimeout(() => {
				if (this.attributes.length > 0) {
					let brand = this.attributes.find((block) => block.id == 2);
					this.companies = brand?.predefinedvalues
				}
			}, 2000);
			// this.$http.post('api/title/fetchproducttitle')
			// 	.then((response) => this.processfetchtitleResponse(response.data))
			// 	.catch((err) => {
			// 		console.log(err)
			// 	});
		},
		processfetchtitleResponse(data) {
			if (this.printchoice == 1) {
				this.productdescription = data.title
			}
		},
		search() {
			this.$store.commit('assignloadingstatus', 1);
			// this.$store.dispatch("fetchproductscount");
			let param = {
				searchtxt: this.searchname,
				// searchstatus: this.searchstatus,
				// searchcategory: this.searchcategory,
				// searchrole: this.searchrole,
				// image: this.images,
				brandid:this.brandid,

				categoryid: this.categoryid,
				currentpage: this.currentpage,
				noofrec: this.noofrec
			};
			this.$http.post("api/products/search", param)
				.then((response) => this.processProductResponse(response.data))
				.catch((err) => {
					this.errorShow(err);
				});
		},
		generatepdf() {
			if (this.productdescription != '') {
				let param = { productdescription: this.productdescription }
				this.$http.post('api/title/createupdate', param)
					.then((response) => this.processProducttitleResponse(response.data))
					.catch((err) => {
						console.log(err)
					});
			}
			this.clickpdf = true
			window.axios({
				url: 'https://cn373vzw7i.execute-api.ap-south-1.amazonaws.com/vsdigidata/pdf/product?productdescription=' + this.productdescription + '&printchoice=' + this.printchoice, // File URL Goes Here
				method: 'GET',
				responseType: 'blob',
			})
				.then(response => {
					this.clickpdf = false
					var fileURL = window.URL.createObjectURL(new Blob([response.data]));
					var fileLink = document.createElement('a');
					fileLink.href = fileURL;

					fileLink.setAttribute('download', 'file.pdf');
					document.body.appendChild(fileLink);
					fileLink.click();
				});
		},
		processProducttitleResponse(data) {
			console.log(data)
		},
		showdescription() {
			this.titleshow = !this.titleshow
		},
		
		parentcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==0)
            }
            return [];
        },
		reloadFun(){
			window.location.reload()
		}
	}
}
</script>
<style scoped>
.card_1 {
	position: relative;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	margin-bottom: 5px;
}

.table td,
.table th {
	border: 1px solid #dee2e6;
}

.example::-webkit-scrollbar {
	display: none;
}
</style>
