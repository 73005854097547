<template>
	<tbody>
		<tr>
			<td>{{ ((currentpage - 1) * noofrec) + (indx + 1) }}</td>
			<td>
				<div v-if="product.category != null">{{ product.category.name }}</div>
			</td>
			<td>
				<div v-for="item in product.attribute" v-bind:key="item.id" :item="item">
					{{ getcompany(item.attribute_value_ids) }}
				</div>
			</td>
			<!--<td><div v-if="product.attribute!=null" >{{product.attribute.attribute_value_ids}}</div></td>-->
			<td>{{ product.name }}</td>
			<td>{{ product.modelno }}</td>
			<td @dblclick="showmrp(product)" @keyup.enter="showmrp(product)" style="color:green">
				<input v-if="mrpfield" type="text" :placeholder="product.mrp" v-model="product.mrp">
				<span>{{ product.mrp }}</span>
			</td>
			<td @dblclick="showsp(product)" @keyup.enter="showsp(product)" style="color:green">
				<input v-if="spfield" type="text" :placeholder="product.sp" v-model="product.sp">
				<span>{{ product.sp }}</span>
			</td>
			<td @dblclick="showemi(product)" @keyup.enter="showemi(product)" style="color:green">
				<input v-if="emifield" type="text" :placeholder="product.emi" v-model="product.emi">
				<span>{{ product.emi }}</span>
			</td>

			<td @dblclick="downpayment(product)" @keyup.enter="downpayment(product)" style="color:green">
				<input v-if="downpaymentfield" type="text" :placeholder="product.downpayment" v-model="product.downpayment">
				<span>{{ product.downpayment }}</span>
			</td>

			<td @dblclick="emidesc(product)" @keyup.enter="emidesc(product)" style="color:green">
				<input v-if="emidescriptionfield" type="textarea" :placeholder="product.emidescription"
					v-model="product.emidescription">
				<span>{{ product.emidescription }}</span>
			</td>
			<td @dblclick="shortdesc(product)" @keyup.enter="shortdesc(product)" style="color:green">
				<input v-if="shortdescriptionfield" type="textarea" :placeholder="product.shortdescription"
					v-model="product.shortdescription">
				<span>{{ product.shortdescription }}</span>
			</td>
			<td v-if="this.$parent.printchoice == '1'" @dblclick="showcommissionField()"
				 style="color:green">
				<input v-if="commissionfield" type="text" :placeholder="product.commission" 
				@keyup.enter="showcommission(product)" v-model="product.commission">
				<span>{{ product.commission }}</span>
			</td>
			<td>
				<button class="btn bg-success text-white btn-xs btnsearch" style="margin-right: -4px"
					@click="triggerimagemodal(product, 'product/' + product.id)">
					<i class="fa fa-file-image-o"></i> Add Img
				</button>
				<div class="card_1">
					<ul style="margin-bottom:1px;padding:0;" v-for="(imageitem, index) in product.images"
						v-bind:key="index">
						<img v-bind:src="imageitem.fullurl" width="50" />
						<button type="button" class="btn btn-outline-danger btn-xs" style="border: none"
							@click="removeimages(imageitem)">
							<i class="fa fa-times"></i>
						</button>
					</ul>
				</div>
			</td>
		</tr>
		<tr v-if="showpaymentform">
			<td colspan="9">
				<set-emi-price-form></set-emi-price-form>
			</td>
		</tr>
	</tbody>
</template>
<script>
import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";

export default {
	mixins: [Constants],
	components: {},
	props: ["product", "indx", "noofrec", "currentpage"],
	data() {
		return {
			showpaymentform: false,
			featureprod: [],
			selectedcategories: [],
			ready: false,
			disabled: false,
			onsale: false,
			outofstock: false,
			trynbuy: false,
			emifield: false,
			spfield: false,
			commissionfield: false,
			downpaymentfield: false,
			emidescriptionfield: false,
			shortdescriptionfield: false,
			mrpfield:false
		};
	},
	mounted() {
		this.initialize();
		this.ready = true;
	},
	watch: {
		product: function () {
			this.initialize();
		},
		feature: function () {
			this.featurespilit();
		},
	},
	computed: {
		getproducturl() {
			return window.axios.defaults.baseURL + "/" + this.product.url;
		},
		...mapGetters([
			"categories",
			"feature",
			"perpagerecord",
			"attributes",
		]),
	},
	methods: {
		getcompany(attribute_value_ids) {
			let brand = this.attributes.find((block) => block.id == +2);
			if (typeof brand !== "undefined") {
				let companyname = brand.predefinedvalues.find(
					(a) => a.id == attribute_value_ids
				);
				if (typeof companyname != "undefined") {
					return companyname.value;
				}
			}
			return attribute_value_ids;
		},
		initialize() {
			if (this.product != null) {
				if (this.product.categoryids != null)
					this.selectedcategories = this.product.categoryids.split(",");
				this.disabled = this.product.disabled == 0 ? false : true;
				this.onsale = this.product.onsale == 0 ? false : true;
				this.outofstock = this.product.outofstock == 0 ? false : true;
				this.trynbuy = this.product.trynbuy == 0 ? false : true;
			}
		},
		featurespilit() {
			if (this.product != null) {
				if (this.product.featureid != 0)
					this.featureprod = this.product.featureid.split(",");
			}
		},
		getsalelabel(product) {
			if (product.status == 0) {
				return "Sale";
			}
		},
		updatefeature() {
			let param = {
				productid: this.product.id,
				featureids: this.featureprod.join(),
			};
			this.$http
				.post("feature/updatefeature", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},

		trynbuyevent() {
			let param = { productid: this.product.id, trynbuy: this.trynbuy ? 0 : 1 };
			this.$http
				.post("api/product/updatetrynbuy", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		outofstockevent() {
			let param = {
				productid: this.product.id,
				outofstock: this.outofstock ? 0 : 1,
			};
			this.$http
				.post("api/product/updateoutofstockstatus", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		onsaledevent() {
			let param = { productid: this.product.id, onsale: this.onsale ? 0 : 1 };
			this.$http
				.post("api/product/updateonsalestatus", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		disabledevent() {
			let param = {
				productid: this.product.id,
				disabled: this.disabled ? 1 : 0,
			};
			this.$http
				.post("api/product/updatedisablestatus", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		updatecategory() {
			let param = {
				productid: this.product.id,
				categoryids: this.selectedcategories.join(),
			};
			this.$http
				.post("api/product/updatecategory", param)
				.then((response) => this.processProductCategoryResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		getcategoryobject(item) {
			let found = this.categories.find((block) => block.id == item);
			if (typeof found !== "undefined") {
				return found;
			}
			return null;
		},
		processProductCategoryResponse(data) {
			if (!data) {
				alert("Could not update");
			}
		},
		removeimages(imageitem) {
			//show the modal to remove the images
			this.$store.commit("assignloadingstatus", 1)
			let param = { id: imageitem.id };
			this.$http
				.post("api/product/removeimage", param)
				.then((response) => this.processResponse(response.data))
				.catch((error) => this.errorShow(error));
		},
		processResponse(data) {
			this.$store.commit("assignloadingstatus", 0)
			this.$store.commit("createupdateallproducts", data);
			this.$parent.refresh();
		},
		payemi() {
			this.showpaymentform = true;
		},
		showemi(product) {
			this.emifield = !this.emifield
			let param = { id: product.id, column: "emi", value: this.product.emi }
			this.$http.post('api/product/updatefield', param)
				.then((response) => this.processUpdateResponse(response.data))
				.catch((err) => {
					this.errorShow(err)
				});
		},
		showcommissionField(){
			this.commissionfield = ! this.commissionfield
		},
		showcommission(product) {
			// this.commissionfield = !this.commissionfield
			let param = { id: product.id, column: "commission", value: this.product.commission }
			this.$http.post('api/product/updatefield', param)
				.then((response) => this.processUpdateResponse(response.data))
				.catch((err) => {
					this.errorShow(err)
				});
		},
		downpayment(product) {
			this.downpaymentfield = !this.downpaymentfield
			let param = { id: product.id, column: "downpayment", value: this.product.downpayment }
			this.$http.post('api/product/updatefield', param)
				.then((response) => this.processUpdateResponse(response.data))
				.catch((err) => {
					this.errorShow(err)
				});
			this.commissionfield = false
		},
		emidesc(product) {
			this.emidescriptionfield = !this.emidescriptionfield
			let param = { id: product.id, column: "emidescription", value: this.product.emidescription }
			this.$http.post('api/product/updatefield', param)
				.then((response) => this.processUpdateResponse(response.data))
				.catch((err) => {
					this.errorShow(err)
				});
		},
		shortdesc(product) {
			this.shortdescriptionfield = !this.shortdescriptionfield
			let param = { id: product.id, column: "shortdescription", value: this.product.shortdescription }
			this.$http.post('api/product/updatefield', param)
				.then((response) => this.processUpdateResponse(response.data))
				.catch((err) => {
					this.errorShow(err)
				});
		},
		showsp(product) {
			this.spfield = !this.spfield

			let param = { id: product.id, column: "sp", value: this.product.sp }
			if(this.product.sp !=undefined){
				this.$http.post('api/product/updatefield', param)
					.then((response) => this.processUpdateResponse(response.data))
					.catch((err) => {
						this.errorShow(err)
					});
			}
		},
		showmrp(product) {
			this.mrpfield = !this.mrpfield
			console.log("mrp :"+product?.mrp)
			let param = { id: product.id, column: "mrp", value:this.product.mrp }
			if(this.product.mrp !=undefined){
				this.$http.post('api/product/updatefield', param)
					.then((response) => this.processUpdateResponse(response.data))
					.catch((err) => {
						this.errorShow(err)
					});
			}
			
		},
		processUpdateResponse(data) {
			this.$notify({
				text: 'Field Updated Successfully', data,
				type: 'success',
				duration: '-1',
				speed: '100',
				closeOnClick: false
			})
		},
		processDisabledResponse() {
			this.$parent.refresh();
			this.$notify({
				text: 'Product Has been disabled Successfully',
				type: 'success',
				duration: '-1',
				speed: '100',
				closeOnClick: false
			})
			this.$store.commit('assignloadingstatus', 0)
			this.$parent.refresh();
		},
		errorShow() {
			this.$store.commit('assignloadingstatus', 0)
			alert("Something went wrong, please try after sometime.")
		}
	}
}
</script>
<style scoped>
input {
	border: none;
	width: 100%;
	height: 100%;
}
</style>